
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      imageSwiperOptions: {
        spaceBetween: 30,
        direction: 'horizontal',
        scrollbar: {
          el: '.swiper-scrollbar',
        },

        mousewheel: false,
        grabCursor: true,
        breakpoints: {
          0: {
            slidesPerView: 2,
          },

          1024: {
            slidesPerView: 4,
          },
        },
      },
    }
  },
}
